@import "./Assets/fonts/Oswald.css";
@import "./Assets/fonts/Poppins.css";
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: var(--ion-color-secondary);
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-secondary-shade);
}
::-webkit-scrollbar-thumb:active {
  background: var(--ion-color-primary);
}
::-webkit-scrollbar-track {
  background: #eaeaea;
}
::-webkit-scrollbar-track:hover {
  background: #eaeaea;
}
::-webkit-scrollbar-track:active {
  background: #eaeaea;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.App {
  overflow: hidden;
}

a.floating-whatsapp {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  padding: 5px 5px 5px 7px;
  box-shadow: 0 0 10px #aaa;
  z-index: 999;

  img {
    width: 48px;
    height: 48px;
  }

  &::before {
    content: "fale conosco";
    position: absolute;
    width: 150px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -160px;
    font-size: 16px;
    top: 15px;
    background-color: #efefef;
    box-shadow: 0 0 10px #aaa;
    color: #2c4760;
    border-radius: 5px;
    font-weight: 500;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Oswald", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--ion-text-color);
}

button,
input,
textarea {
  font-family: "Oswald", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  outline: none !important;
  box-sizing: border-box;
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #073f9d;
  --ion-color-primary-rgb: 7, 63, 157;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-shade: #002f80;
  --ion-color-primary-tint: #1f5bc4;

  /** secondary **/
  --ion-color-secondary: #414c5c;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-shade: #273242;
  --ion-color-secondary-tint: #697281;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #62c235;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-shade: #41a016;
  --ion-color-success-tint: #90e768;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-contrast: #000000;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: #121212;

  --ion-text-color: #68717d;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}

button {
  &:disabled {
    opacity: 0.5 !important;
  }
}

.hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
}

.inactive {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

#modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .content {
    width: 500px;
    max-width: 90%;
    background-color: white;
    padding: 50px;
    border-radius: 5px;
    text-align: center;
    h3 {
      font-size: 32px;
      font-weight: bold;
      color: var(--ion-text-color);
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      color: var(--ion-text-color);
      font-weight: 500;
      margin-bottom: 50px;
    }

    button {
      width: 240px;
      height: 44px;
      background: var(--ion-color-primary);
      border-radius: 10px;
      display: block;
      margin: 0 auto;
      color: white;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.container {
  display: block;
  width: 100%;
  max-width: 1100px;
  padding: 0 1.5rem;
  margin: 0 auto;
}

.product-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;

  a.product {
    color: var(--ion-color-secondary);
    text-transform: uppercase;
    text-decoration: none;
    .image {
      width: 100%;
      height: 250px;
      position: relative;
      background-size: cover;
      background-position: bottom center;
      background-repeat: no-repeat;
      background-color: #eee;
      background-blend-mode: multiply;
      p {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.1);
        opacity: 0;
        transition: 0.3s;
        font-size: 16px;
      }
    }

    .info {
      margin: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      h3 {
        font-size: 15px;
        margin-bottom: 5px;
      }
      p {
        font-size: 14px;
        font-weight: 300;
      }

      span {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--ion-color-primary);
        border-radius: 5px;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    &:hover {
      .image {
        p {
          opacity: 1;
        }
      }
    }
  }
}

form {
  .check {
    margin-top: 15px;
    position: relative;
    input {
      position: absolute;
      left: 9px;
      top: 9px;
      width: 1px;
      height: 1px;
      opacity: 0.1;
      pointer-events: none;

      + label {
        cursor: pointer;
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
        text-align: left;
        color: var(--ion-text-color);
        display: grid;
        grid-template-columns: 25px 1fr;
        gap: 10px;

        span {
          width: 25px;
          height: 25px;
          border: 2px solid var(--ion-text-color);
          border-radius: 5px;
          position: relative;

          &::before {
            content: "";
            width: 15px;
            height: 15px;
            background-color: var(--ion-text-color);
            position: absolute;
            top: 3px;
            left: 3px;
            border-radius: 3px;
            transition: 0.3s;
            opacity: 0;
          }
        }
      }

      &:checked {
        + label {
          span {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.form-group {
  position: relative;
  margin-top: 20px;
  input,
  textarea {
    background: #eeeeee;
    border: 0.5px solid #c1c5cc;
    box-sizing: border-box;
    border-radius: 5px;
    height: 36px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: var(--ion-color-secondary);
    padding-left: 15px;
    + label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      color: var(--ion-color-secondary);
      opacity: 1;
      position: absolute;
      left: 15px;
      top: 7px;
      transition: 0.3s;
      pointer-events: none;
    }

    &:valid,
    &:focus,
    &:read-only {
      + label {
        top: -20px;
        left: 0;
        font-size: 12px;
        opacity: 1;
        text-transform: lowercase;
      }
    }
  }

  textarea {
    height: 70px;
    resize: none;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: unset;
  }

  .product-grid {
    grid-template-columns: 1fr 1fr;

    a.product {
      .image {
        height: 40vw;
      }

      .image {
        p {
          font-size: 12px;
          line-height: 14px;
        }
      }

      .info {
        h3 {
          font-size: 12px;
        }
        p {
          font-size: 12px;
        }
        span {
          display: none;
        }
      }
    }
  }
}
